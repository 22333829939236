import { Checkbox, FormControlLabel, Grid, Slider, Switch } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../store';
import { changeSeed, changeTnt, TopologyRouterSelectors } from '../../../store/router/topologyActions';
import { updateClientFilter } from '../../../store/slices/filtersSlice';
import { AvatarColor, svgIcons } from '../../common/entities/enums';
import CustomSVGIcon from '../../common/misc/CustomSvgIcon';
import CustomTooltip from '../../common/misc/CustomTooltip';
import { PersonSelectionMode } from '../TopologyView';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends ReduxProps<typeof mapDispatchToProps, typeof mapStateToProps> {
    synapsePersonInteractionMode: PersonSelectionMode;
    setViewMode: () => void;
}

interface SynapseToolbarState {
    isMerging: boolean;
    sliderValue: number;
}

class SynapseToolbar extends Component<Props & WithTranslation, SynapseToolbarState> {
    minimumSliderValueInGroupTopology = 2; // Number of Connections Within a Group can be minimum 2.

    constructor(props) {
        super(props);
        this.state = {
            isMerging: false,
            sliderValue: props.clientFilters.strength
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.clientFilters.strength !== this.props.clientFilters.strength) {
            this.setState({ sliderValue: this.props.clientFilters.strength });
        }
    }

    getSliderText = () => {
        if (this.props.isTnt) {
            return this.props.t('synapse_toolbar.number_middleman_outgoing_relationships');
        }
        if (this.props.synapseMetadata.isGroupTopology) {
            return this.props.t('synapse_toolbar.number_of_relationships_within_group');
        }
        return this.props.t('synapse_toolbar.relationship_strength');
    };

    getMinAndMaxSliderValue = () => {
        let minSliderValue = 1;
        let maxSliderValue = 10;

        if (this.props.isTnt) {
            let minPeopleConnectedToFirstSphere;
            let maxPeopleConnectedToFirstSphere = 1;
            this.props.synapse.personsInSynapse.forEach((person) => {
                if (this.props.synapse.seedIds.some((seed) => seed !== person.personUID)) {
                    const personConnectionsCount = this.props.synapse.connections.filter(
                        (connection) => connection.fromPersonUID === person.personUID
                    ).length;
                    if (personConnectionsCount > 0) {
                        if (!minPeopleConnectedToFirstSphere) {
                            minPeopleConnectedToFirstSphere = personConnectionsCount;
                        } else {
                            minPeopleConnectedToFirstSphere = Math.min(
                                personConnectionsCount,
                                minPeopleConnectedToFirstSphere
                            );
                        }
                    }

                    maxPeopleConnectedToFirstSphere = Math.max(personConnectionsCount, maxPeopleConnectedToFirstSphere);
                }
            });
            minSliderValue = minPeopleConnectedToFirstSphere || 1;
            maxSliderValue = maxPeopleConnectedToFirstSphere;
        }
        if (this.props.synapseMetadata.isGroupTopology) {
            minSliderValue = this.minimumSliderValueInGroupTopology; // TODO should be dynamic
            maxSliderValue = this.props.synapseMetadata.maxPersonToSeedGroupConnections;
        }

        return [minSliderValue, maxSliderValue];
    };

    render() {
        return this.renderViewMode();
    }

    isIndirectConnectionsDisabled = () => {
        const { personsInSynapse, seedIds, connections } = this.props.topology.synapses.tnt;
        return (
            personsInSynapse.length === 0 ||
            (personsInSynapse.length <= seedIds.length &&
                connections.every(
                    (connection) =>
                        seedIds.includes(connection.fromPersonUID) && seedIds.includes(connection.toPersonUID)
                ))
        );
    };

    handleStrengthSliderChange = _.debounce(
        (strength: number) => this.props.updateClientFilter({ key: 'strength', value: strength }),
        250
    );

    getLegendRow = (color: AvatarColor, text: string) => (
        <div className='flex-align-center'>
            <div
                style={{
                    width: '16px',
                    height: '10px',
                    backgroundColor: color,
                    marginRight: '5px',
                    marginBottom: '1px'
                }}
            />
            <span>{text}</span>
        </div>
    );

    renderViewMode = () => {
        const isIndirectConnectionsDisabled = this.isIndirectConnectionsDisabled();

        const [minSliderValue, maxSliderValue] = this.getMinAndMaxSliderValue();
        return (
            <>
                <Grid container item>
                    <div style={{ margin: 'auto' }} className='flex-align-center'>
                        <CustomTooltip
                            title={
                                isIndirectConnectionsDisabled
                                    ? this.props.t('synapse_toolbar.no_indirect_relationships')
                                    : ''
                            }>
                            <FormControlLabel
                                style={{ margin: 'auto' }}
                                control={
                                    <Switch
                                        checked={this.props.isTnt}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            this.props.changeTnt(e.target.checked)
                                        }
                                        color='primary'
                                    />
                                }
                                label={
                                    <Typography color='textSecondary'>
                                        {this.props.t('synapse_toolbar.indirect_relationships')}
                                    </Typography>
                                }
                                disabled={isIndirectConnectionsDisabled}
                            />
                        </CustomTooltip>
                        <CustomSVGIcon
                            type={svgIcons.info}
                            size={25}
                            customStyle={{ margin: '0 10px' }}
                            tooltip={
                                <div style={{ width: '450px' }}>
                                    <div style={{ marginBottom: '4px' }}>
                                        {this.props.synapseMetadata.isGroupTopology
                                            ? this.props.t('synapse_toolbar.indirect_relationships_tooltip_group')
                                            : this.props.t('synapse_toolbar.indirect_relationships_tooltip')}
                                    </div>
                                    {this.getLegendRow(AvatarColor.seed, this.props.t('synapse_toolbar.seed'))}
                                    {this.getLegendRow(AvatarColor.regular, this.props.t('synapse_toolbar.middlemen'))}
                                    {this.getLegendRow(
                                        AvatarColor.identifiedTnt,
                                        this.props.t('synapse_toolbar.indirect_relationships')
                                    )}
                                </div>
                            }
                            tooltipProps={{ placement: 'right-start' }}
                        />
                    </div>
                </Grid>
                {!(this.props.isTnt && this.props.synapseMetadata.isGroupTopology) && (
                    <Grid item container direction='column'>
                        <div className='flex-column'>
                            <Typography
                                color='textSecondary'
                                style={{ margin: 'auto', fontSize: 12, paddingTop: '6px' }}>
                                {this.getSliderText()}
                            </Typography>
                            <Slider
                                style={{ margin: 'auto', width: '80%' }}
                                defaultValue={1}
                                aria-labelledby='connection strength'
                                valueLabelDisplay='auto'
                                step={1}
                                marks
                                value={this.state.sliderValue}
                                onChange={(e, value: number) => {
                                    this.setState({ sliderValue: value });
                                    this.handleStrengthSliderChange(value);
                                }}
                                min={minSliderValue}
                                max={maxSliderValue}
                                disabled={minSliderValue === maxSliderValue}
                            />
                        </div>
                    </Grid>
                )}

                <Grid container item>
                    <FormControlLabel
                        style={{ margin: 'auto' }}
                        control={
                            <Checkbox
                                checked={this.props.clientFilters.isLabeledOnly}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    this.props.updateClientFilter({
                                        key: 'isLabeledOnly',
                                        value: e.target.checked
                                    });
                                }}
                                color='primary'
                            />
                        }
                        disabled={this.props.isTnt}
                        label={
                            <Typography color='textSecondary'>
                                {this.props.t('synapse_toolbar.only_labeled')}
                            </Typography>
                        }
                    />
                </Grid>
            </>
        );
    };
}

const mapStateToProps = (state: RootState) => ({
    topology: state.topology.data,
    synapse: state.topology.currentSynapse,
    synapseMetadata: state.topology.synapseMetadata,
    clientFilters: state.filters.clientFilters,
    isTnt: TopologyRouterSelectors.isTnt(state),
    watchlistsIds: TopologyRouterSelectors.getWatchlistIds(state),
    commonTopologiesIds: TopologyRouterSelectors.getCommonTopologies(state)
});

const mapDispatchToProps = { changeSeed, changeTnt, updateClientFilter };

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('topologyView')(SynapseToolbar));
