import { Button, Grid, styled } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import React, { useState } from 'react';

import authService from '../../services/authService';
import CommonService from '../../services/commonService';
import { Permissions } from '../../shared/model/Permissions';

import { Age, Gender } from '../../store/slices/filtersSlice';
import { SynapseMetaData } from '../../store/slices/topologySlice';
import { getFinishedWatchlists, WatchlistEntry } from '../../store/slices/watchlistsSlice';
import { AvatarColor } from '../common/entities/enums';

import ExportToZipDialog, { ExportDialogMode } from '../dialogs/exportToZipDialog';
import AddLabel from './addLabel';
import AddPersonToWatchlist from './addPersonToWatchlist';
import EditPerson from './editPerson';

import SelectedConnectionActions from './selectedConnectionActions';
import IndividualDetails from './individualDetails';

import { PersonDescriptor } from './VisionSynapse';
import './styles/sidePanel.less';

import { useTranslation } from 'react-i18next';

import SidePanelGalleryButton from './SidePanelGalleryButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { changeSeed, TopologyRouterSelectors } from '../../store/router/topologyActions';
import VvAvatar from '../common/vvAvatar';
import { Colors } from '../colors';
import Loader from 'react-spinners/PulseLoader';
import { PersonSelectionMode, ViewMode } from './TopologyView';
import { StyleVariables } from '../styleVariables';

interface SidePanelSingleSelectProps {
    handleSeeSelectedPhotosClick: () => void;
    onDeleteClick: (persons: PersonDescriptor[]) => void;
    onDetailsClick: () => void;
    onMergeClick: () => void;
    doMerge: (mergeInto: PersonDescriptor, merging: PersonDescriptor[]) => Promise<void>;
    onSplitClick: () => void;
    onAddLabelClick: () => void;
    onAddPersonToWatchlistClick: () => void;
    onEditPersonProperties: () => void;
    onExportToZip: () => void;
    showAddLabel: boolean;
    showAddPersonToWatchlist: boolean;
    showEditPerson: boolean;
    showExportToZip: boolean;
    showDetails: boolean;
    handleLabelClose: () => void;
    handleEditPersonClose: () => void;
    handleExportToZipProcessing: (handleExportToZipProcessing: ExportDialogMode) => void;
    handleExportToZipCancel: () => void;
    isExportToZipLoading: boolean;
    scrollToBottomRef: React.RefObject<HTMLDivElement>;
    onSaveOrCancelWatchlist: () => void;
    onCloseIndividualDetails: () => void;
    caseName: string;
    mergePersonTarget: PersonDescriptor;
    personsToMerge: PersonDescriptor[];
    setViewMode: (personSelectionMode: PersonSelectionMode) => void;
}

const MergeButton = styled(Button)({
    marginRight: StyleVariables.gap
});

const CancelButton = styled(Button)({
    marginLeft: StyleVariables.gap
});

const ButtonsWrapper = styled(Grid)({
    display: 'flex',
    justifyContent: 'center',
    marginTop: StyleVariables.gap
});

const getSelectedPersonTooltip = (selectedPerson: PersonDescriptor, watchlistEntries: WatchlistEntry[]) => {
    if (!selectedPerson) {
        return '';
    }
    return authService.hasPermissions(Permissions.show_key_individuals_score)
        ? CommonService.getAllLabelsAndScoreForToolTip(selectedPerson.labels, selectedPerson.score, watchlistEntries)
        : CommonService.getAllLabelsForToolTip(selectedPerson.labels, watchlistEntries);
};

const SidePanelSingleSelect: React.FC<SidePanelSingleSelectProps> = (props) => {
    const { t } = useTranslation(['topologyView']);
    const dispatch = useDispatch();
    const seedIds: string[] = useSelector((state: RootState) => TopologyRouterSelectors.getSeedIds(state) || []);
    const selectedPerson: PersonDescriptor = useSelector((state: RootState) => state.topology.selectedConnection);
    const synapseMetadata: SynapseMetaData = useSelector((state: RootState) => state.topology.synapseMetadata);
    const batchId: string = useSelector((state: RootState) => TopologyRouterSelectors.getBatchId(state));
    const watchlistEntries: WatchlistEntry[] = useSelector((state: RootState) => getFinishedWatchlists(state));
    const labelsText: string = getSelectedPersonTooltip(selectedPerson, watchlistEntries);
    const [isMerging, setIsMerging] = useState(false);

    const getAvatarTitle = () => {
        if (props.mergePersonTarget?.personUID) {
            return t('side_panel.selected_relationship');
        }
        if (seedIds.includes(selectedPerson.personUID)) {
            return t('synapse_toolbar.seed');
        }
        return t('side_panel.selected_relationship');
    };
    seedIds.includes(selectedPerson.personUID) ? t('synapse_toolbar.seed') : t('side_panel.selected_relationship');

    const handleDeleteClick = () => props.onDeleteClick([selectedPerson]);

    const getAvatarColor = () => {
        if (props.mergePersonTarget?.personUID) {
            return AvatarColor.merge;
        }
        if (seedIds.includes(selectedPerson.personUID)) {
            return AvatarColor.seed;
        }
        return AvatarColor.selectedConnection;
    };

    const getAvatarImage = () => {
        if (props.mergePersonTarget?.representingPhotoId) {
            return `/api/photo/${props.mergePersonTarget.representingPhotoId}`;
        }
        return `/api/photo/${selectedPerson.representingPhotoId}`;
    };

    const executeMergeRequest = async () => {
        setIsMerging(true);
        await props.doMerge(props.mergePersonTarget, props.personsToMerge);
        setIsMerging(false);
        props.setViewMode(new ViewMode());
    };

    return (
        <div className='side-panel-section selected-connection-container'>
            <>
                <div className='side-panel-section-header avatar-title-text'>{getAvatarTitle()}</div>
                <Avatar
                    className='avatar bigAvatar'
                    imgProps={{}}
                    alt={labelsText}
                    title={labelsText}
                    src={getAvatarImage()}
                    style={{
                        borderColor: getAvatarColor()
                    }}
                />
                <div title={labelsText} className='label-name'>
                    {CommonService.getHighestPriority(selectedPerson.labels)}{' '}
                    {selectedPerson.labels &&
                        selectedPerson.labels.length > 1 &&
                        `+(${selectedPerson.labels.length - 1})`}
                </div>
            </>

            <Grid item style={{ flex: 1, marginTop: '4px' }}>
                <div style={{ width: 'fit-content', margin: '0 auto' }}>
                    {authService.hasPermissions(Permissions.show_gender) && selectedPerson.gender !== Gender.Unknown && (
                        <Typography>
                            {t('side_panel.gender')}:{' '}
                            {t(`gender_filter.${selectedPerson.gender}`, {
                                ns: 'filters'
                            })}
                        </Typography>
                    )}
                    {authService.hasPermissions(Permissions.show_age) && selectedPerson.age !== Age.Unknown && (
                        <Typography>
                            {t('side_panel.age')}:{' '}
                            {t(`age_filter.${selectedPerson.age}`, {
                                ns: 'filters'
                            })}
                        </Typography>
                    )}
                </div>
            </Grid>

            {!props.mergePersonTarget?.personUID && (
                <SidePanelGalleryButton
                    selectedPerson={selectedPerson}
                    synapseMetadata={synapseMetadata}
                    handleSeeSelectedPhotosClick={props.handleSeeSelectedPhotosClick}
                    seedIds={seedIds}
                />
            )}

            {!props.mergePersonTarget?.personUID && (
                <SelectedConnectionActions
                    onDeleteClick={handleDeleteClick}
                    onDetailsClick={props.onDetailsClick}
                    onMergeClick={props.onMergeClick}
                    onSplitClick={props.onSplitClick}
                    onAddLabelClick={props.onAddLabelClick}
                    onAddPersonToWatchlistClick={props.onAddPersonToWatchlistClick}
                    onEditPersonProperties={props.onEditPersonProperties}
                    onExportToZip={props.onExportToZip}
                    isSeed={seedIds.includes(selectedPerson.personUID)}
                    isGroup={synapseMetadata.isGroupTopology}
                    selectedPersonFilteredPhotosCount={synapseMetadata.filteredSelectedPhotoUIDs.length}
                />
            )}

            {props.mergePersonTarget?.personUID && (
                <div>
                    <Grid container item style={{ flexGrow: 2, display: 'flex', justifyContent: 'center' }}>
                        {props.personsToMerge?.map((person) => (
                            <VvAvatar
                                key={person.personUID}
                                person={person}
                                onClick={() => {
                                    dispatch(changeSeed(person.personUID));
                                }}
                                borderColor={Colors.clickable}
                            />
                        ))}
                    </Grid>
                    <ButtonsWrapper container item>
                        <Grid item>
                            <MergeButton
                                color='primary'
                                variant='contained'
                                disabled={isMerging || props.personsToMerge?.length === 0}
                                onClick={executeMergeRequest}>
                                {isMerging ? (
                                    <Loader size={10} color={Colors.white}></Loader>
                                ) : (
                                    t('synapse_toolbar.merge')
                                )}
                            </MergeButton>
                        </Grid>
                        <Grid item>
                            <CancelButton
                                disabled={isMerging}
                                variant='outlined'
                                color='primary'
                                onClick={props.setViewMode}>
                                {t('synapse_toolbar.cancel')}
                            </CancelButton>
                        </Grid>
                    </ButtonsWrapper>
                </div>
            )}

            {!!props.showAddLabel && <AddLabel person={selectedPerson} onSaveOrCancel={props.handleLabelClose} />}

            {!!props.showAddPersonToWatchlist && (
                <AddPersonToWatchlist
                    selectedPerson={selectedPerson}
                    watchlists={watchlistEntries}
                    onSaveOrCancel={props.onSaveOrCancelWatchlist}
                    caseName={props.caseName}
                />
            )}
            {!!props.showEditPerson && (
                <EditPerson
                    batchId={batchId}
                    selectedPerson={selectedPerson}
                    onSaveOrCancel={props.handleEditPersonClose}
                />
            )}
            {!!props.showExportToZip && (
                <ExportToZipDialog
                    onCancel={props.handleExportToZipCancel}
                    onClose={props.handleExportToZipProcessing}
                    isLoading={props.isExportToZipLoading}
                />
            )}
            {!!props.showDetails && (
                <IndividualDetails individual={selectedPerson} onClose={props.onCloseIndividualDetails} />
            )}
            <div ref={props.scrollToBottomRef} />
        </div>
    );
};

export default SidePanelSingleSelect;
