import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    DialogContentText,
    Divider,
    Typography,
    Checkbox,
    FormControlLabel,
    Card,
    CardActions,
    CardContent
} from '@material-ui/core';
import React from 'react';
import { useState, useEffect } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import ExportService, { ExportData, ExportStatus } from '../../../services/exportService';
import { TopologyEntry } from '../../../store/slices/topologiesSlice';
import { Colors } from '../../colors';
import useInterval from '../../hooks/useInterval';
import { WatchlistEntry } from '../../../store/slices/watchlistsSlice';
import './exportQueryModal.less';
import { useTranslation } from 'react-i18next';
import { ExportErrorMessage } from '../entities/enums';

interface ExportQueryModalProps {
    onClose: () => void;
    query: TopologyEntry | WatchlistEntry;
    isWatchlist: boolean;
}

const ExportQueryModal: React.FC<ExportQueryModalProps> = (props) => {
    const { t } = useTranslation(['common', 'errors']);

    const [flaggedOnly, setFlaggedOnly] = useState(false);

    const [isFetchingExportData, setIsFetchingExportData] = useState(true);
    const [isExporting, setIsExporting] = useState(false);
    const [isCanceling, setIsCanceling] = useState(false);
    const [exportData, setExportData] = useState<ExportData | null>(null);

    useEffect(() => {
        fetchExportData();
    }, []);

    const fetchExportData = async () => {
        const response = await ExportService.fetchExportData(props.query.batchId);
        setExportData(response.data);
        if (isFetchingExportData) {
            setIsFetchingExportData(false);
        }
    };

    useInterval(fetchExportData, 10000);

    const handleExportClicked = async () => {
        setIsExporting(true);

        try {
            await ExportService.exportQueryImages({
                queryId: props.query.batchId,
                flagged: flaggedOnly,
                isWatchlist: props.isWatchlist
            });
            await fetchExportData();
        } catch (error) {
            toast.error(t('general_err_msg', { ns: 'errors' }));
        } finally {
            setIsExporting(false);
        }
    };

    const handleCancelClicked = async () => {
        setIsCanceling(true);
        try {
            await ExportService.cancelExportQueryImages(props.query.batchId);
            await fetchExportData();
        } catch (error) {
            toast.error(t('general_err_msg', { ns: 'errors' }));
        } finally {
            setIsCanceling(false);
        }
    };

    const renderFilters = () => (
        <div className='filters-container'>
            <FormControlLabel
                control={
                    <Checkbox color='primary' checked={flaggedOnly} onChange={(e) => setFlaggedOnly(!flaggedOnly)} />
                }
                label={t('export_query_modal.flagged_only_label')}
            />
        </div>
    );

    const renderExportOrCancelButton = () => {
        const ButtonLoader = (
            <div className='flex-center'>
                <PulseLoader size={8} color={Colors.white}></PulseLoader>
            </div>
        );

        return (
            <div className='export-or-cancel-button-container'>
                {exportData?.exportStatus === ExportStatus.Processing ? (
                    <Button disabled={isCanceling} onClick={handleCancelClicked} color='primary' variant='outlined'>
                        {isCanceling ? ButtonLoader : t('export_query_modal.cancel')}
                    </Button>
                ) : (
                    <Button disabled={isExporting} onClick={handleExportClicked} color='primary' variant='contained'>
                        {isExporting ? ButtonLoader : t('export_query_modal.export')}
                    </Button>
                )}
            </div>
        );
    };

    const renderExportData = () => {
        const isExpired = exportData.runExpirationDate && exportData.runExpirationDate - new Date().getTime() < 0;

        const getStatus = () => {
            if (exportData?.error === ExportErrorMessage.ERR_NO_PHOTOS) {
                return t('export_query_modal.no_images_found');
            }
            if (exportData?.error === ExportErrorMessage.ERR_AMAZON) {
                return t('export_query_modal.amazon_error');
            }
            if (isExpired) {
                return t('export_query_modal.expired');
            }

            return t('topologyStatus.' + exportData.exportStatus);
        };
        return (
            <Card style={{ backgroundColor: '#424242' }} raised className='export-data'>
                <CardContent>
                    <Typography className='export-data-title' color='textPrimary' gutterBottom>
                        {t('export_query_modal.start_date', { date: new Date(exportData.runCreatedAt) })},{' '}
                        {t('export_query_modal.status')}:{' '}
                        <span className={`status ${isExpired ? 'expired' : exportData.exportStatus.toLowerCase()}`}>
                            {getStatus()}
                        </span>
                    </Typography>

                    {exportData.runExpirationDate && (
                        <Typography color='textPrimary' gutterBottom>
                            {t('export_query_modal.export_be_available_until', {
                                date: new Date(exportData.runExpirationDate)
                            })}
                        </Typography>
                    )}

                    <Typography color='textPrimary' gutterBottom>
                        {t('export_query_modal.created_by')}: {exportData.userName}
                    </Typography>

                    {!props.isWatchlist && (
                        <Typography color='textPrimary' gutterBottom>
                            {t('export_query_modal.flagged_only')}:{' '}
                            {exportData.flagged ? t('export_query_modal.enabled') : t('export_query_modal.disabled')}
                        </Typography>
                    )}

                    {exportData.runFinishedAt && (
                        <Typography color='textPrimary' gutterBottom>
                            {t('export_query_modal.end_date', {
                                date: new Date(exportData.runFinishedAt)
                            })}{' '}
                        </Typography>
                    )}
                </CardContent>
                {!isExpired && exportData.exportLink && (
                    <CardActions>
                        <Button color='primary' variant='text' size='small' href={exportData.exportLink}>
                            {t('export_query_modal.click_to_download_export')}
                        </Button>
                    </CardActions>
                )}
            </Card>
        );
    };

    return (
        <Dialog className='export-query-modal' maxWidth='lg' open onClose={props.onClose} fullWidth>
            <DialogTitle>
                {t('export_query_modal.export_images_of')} "{props.query.name}"
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('export_query_modal.export_to_zip_can_take_a_while')}
                    <br />
                    {t('export_query_modal.link_available_for')}
                </DialogContentText>

                {!props.isWatchlist && renderFilters()}
                {renderExportOrCancelButton()}

                <Divider className='divider' />

                <Typography className='exports-title' variant='subtitle1'>
                    {t('export_query_modal.exports')}:
                </Typography>
                {isFetchingExportData && (
                    <div className='flex-center'>
                        <ClipLoader size={20} color={Colors.white}></ClipLoader>
                    </div>
                )}

                {exportData && renderExportData()}

                {!isFetchingExportData && !exportData && (
                    <Typography variant='subtitle2'>{t('export_query_modal.no_exports_found')}</Typography>
                )}
            </DialogContent>

            <DialogActions>
                <Button onClick={props.onClose} color='primary' autoFocus>
                    {t('export_query_modal.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExportQueryModal;
