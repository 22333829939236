import React from 'react';
import { List } from 'react-virtualized';
import VvAvatar from '../common/vvAvatar';
import { PersonDescriptor } from './VisionSynapse';

interface VirtualAvatarListProps {
    persons: PersonDescriptor[];
    onDoubleClick(person: PersonDescriptor);
    doSelectPerson(person: PersonDescriptor, ctrlKey: boolean);
    maxRows: number;
    rowHeight?: number;
    peopleInRow?: number;
    mergePersonTarget?: PersonDescriptor;
    personsToMerge?: PersonDescriptor[];
}

const VirtualAvatarList: React.FC<VirtualAvatarListProps> = ({ peopleInRow = 5, rowHeight = 42, ...props }) => {
    const renderPerson = (person: PersonDescriptor) => (
        <VvAvatar
            key={person.personUID}
            person={person}
            onClick={props.doSelectPerson}
            onDoubleClick={props.onDoubleClick}
            mergePersonTarget={props.mergePersonTarget}
            personsToMerge={props.personsToMerge}
        />
    );

    const rowRenderer = ({ key, index, style }) => {
        const start = (index + 1) * peopleInRow - peopleInRow;
        const persons = props.persons.slice(start, start + peopleInRow);

        if (!persons || persons.length === 0) {
            return null;
        }

        return (
            <div key={key} style={style} className='flex-justify-center'>
                {[...persons].map((person, i) => (
                    <div key={person.personUID} onDoubleClick={() => props.onDoubleClick(person)}>
                        {renderPerson(person)}
                    </div>
                ))}
            </div>
        );
    };

    const rowCount = Math.ceil(props.persons.length / peopleInRow);
    return (
        <List
            style={{ outline: 'none' }}
            width={288}
            height={rowCount >= props.maxRows ? rowHeight * props.maxRows : rowCount * rowHeight}
            rowCount={rowCount}
            rowHeight={rowHeight}
            rowRenderer={rowRenderer}
        />
    );
};
export default VirtualAvatarList;
