import { ExportErrorMessage } from '../components/common/entities/enums';
import AjaxClient from './ajaxClient';

export interface ExportData {
    runCreatedAt: number;
    runFinishedAt: number;
    runExpirationDate: number;
    flowId: string;
    exportStatus: ExportStatus;
    error: ExportErrorMessage.ERR_GENERAL | ExportErrorMessage.ERR_NO_PHOTOS | ExportErrorMessage.ERR_AMAZON;
    exportLink: string;
    flagged: boolean;
    userName: string;
}

export enum ExportStatus {
    Finished = 'FINISHED',
    Error = 'ERROR',
    Processing = 'PROCESSING',
    Stopped = 'STOPPED'
}

export interface ExportQueryImagesRequest {
    queryId: string;
    flagged: boolean;
    isWatchlist: boolean;
}

export interface ExportPersonsRequest {
    userIds: string[];
    queryId: string;
}

const ExportService = {
    exportPersonToZip(data: ExportPersonsRequest) {
        return AjaxClient.post<string>(`/export/users-photos`, data);
    },
    fetchExportData(queryId: string) {
        return AjaxClient.get<ExportData>(`/export/query/${queryId}`);
    },
    exportQueryImages(data: ExportQueryImagesRequest) {
        return AjaxClient.post<ExportData>(`/export/query`, data);
    },
    cancelExportQueryImages(queryId: string) {
        return AjaxClient.delete<ExportData>(`/export/query/${queryId}`);
    }
};

export default ExportService;
