import {
    Button,
    Drawer,
    Radio,
    RadioGroup,
    FormControl,
    FormLabel,
    FormControlLabel,
    withStyles
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import React, { ChangeEvent, Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ApiService from '../../services/apiService';
import authService from '../../services/authService';
import CommonService from '../../services/commonService';
import { Permissions } from '../../shared/model/Permissions';
import { RootState } from '../../store';
import { changeSeed, navigateSynapse, TopologyRouterSelectors } from '../../store/router/topologyActions';
import { activeServerFiltersCount, ServerFilters, updateServerFilters } from '../../store/slices/filtersSlice';
import { fetchTopology, toggleFiltersPanel } from '../../store/slices/topologySlice';
import { getFinishedTopologies, TopologyEntry } from '../../store/slices/topologiesSlice';
import { getFinishedWatchlists, WatchlistEntry } from '../../store/slices/watchlistsSlice';
import { svgIcons } from '../common/entities/enums';
import Logo from '../common/logo/logo';
import CustomSVGIcon from '../common/misc/CustomSvgIcon';
import { ExportDialogMode } from '../dialogs/exportToZipDialog';
import SideFilters from '../sideFilters/sideFilters';
import KeyIndividuals from './keyIndividuals';
import SearchSectionContainer from './search/searchSectionContainer';
import SidePanelMultiSelect from './sidePanelMultiSelect';
import VirtualAvatarList from './virtualAvatarList';
import { Label, PersonDescriptor, PhotoAppearance, PhotoIdToAppearence, SynapsePhoto } from './VisionSynapse';
import TopologyPopupGalleryWrapper from '../popupGallery/topologyPopupGalleryWrapper';
import './styles/sidePanel.less';
import GalleryService from '../../services/galleryService';
import { Image } from '../popupImage/popupImageSectionContainer';
import ExportService, { ExportPersonsRequest } from '../../services/exportService';
import TopologyViewService from './topologyViewService';
import { Colors } from '../colors';
import { StyleVariables } from '../styleVariables';
import { withTranslation, WithTranslation } from 'react-i18next';
import { synapsePhotoToImage } from '../popupGallery/popupGalleryHelpers';
import { setShowLoader } from '../../store/slices/searchSlice';
import SidePanelSingleSelect from './SidePanelSingleSelect';
import SidePanelMultipleSelect from './SidePanelMultipleSelect';
import { PersonSelectionMode } from './TopologyView';

interface SidePanelState {
    showAddLabel: boolean;
    showDetails: boolean;
    showEditPerson: boolean;
    showAddPersonToWatchlist: boolean;
    showExportToZip: boolean;
    showGallery: boolean;
    isExportToZipLoading: boolean;
    selectedWatchlists: WatchlistEntry[];
    selectedTopologies: TopologyEntry[];
    allTopologiesExceptCurrentTopology: TopologyEntry[];
    individualSortType: IndividualSortType;
    isPersonToSplitSelected: boolean;
    selectedPersonAllImages: Image[];
}

interface SidePanelProps extends ReduxProps<typeof mapDispatchToProps, typeof mapStateToProps> {
    doSelectPerson: (person: PersonDescriptor, ctrlKey: boolean) => void;
    setViewMode: (personSelectionMode: PersonSelectionMode) => void;
    setMergingMode: (toPerson: PersonDescriptor) => void;
    mergePersonTarget: PersonDescriptor;
    personsToMerge: PersonDescriptor[];
    doMerge: (mergeInto: PersonDescriptor, merging: PersonDescriptor[]) => Promise<void>;
    onDeleteMultiplePersons: (persons: PersonDescriptor[]) => void;
    optimisticSetLabelToAfterEdit: (personWithNewLabels: PersonDescriptor) => void;
    optimisticUpdatePerson: (updatedPerson: PersonDescriptor) => void;
    filtersButtonHidden: boolean;
    resetViewMode: () => void;
}

const StyledDrawer = withStyles({
    paper: {
        height: 'calc(100% - 62px)',
        top: 62,
        backgroundColor: '#171919'
    }
})(Drawer);

const StyledFormControl = withStyles({
    root: {
        flexDirection: 'row',
        alignItems: 'center',
        '& .MuiFormGroup-root': {
            height: '100%',
            alignItems: 'center'
        },
        '& .MuiRadio-root': {
            display: 'none'
        },
        '& .MuiFormControlLabel-root': {
            marginLeft: 0,
            marginRight: 0,
            '& ~ .MuiFormControlLabel-root': {
                marginLeft: StyleVariables.gap
            }
        },
        '& .MuiFormControlLabel-label': {
            fontSize: '10px',
            fontWeight: '500',
            color: Colors.clickable,
            height: '24px',
            lineHeight: '24px',
            paddingRight: StyleVariables.gap,
            paddingLeft: StyleVariables.gap,
            border: `1px solid ${Colors.clickable}`,
            borderRadius: '100px',
            transition: 'background-color 280ms'
        },
        '& .MuiRadio-root:not(.Mui-checked):not(.Mui-disabled) ~ .MuiFormControlLabel-label:hover': {
            color: Colors.white,
            backgroundColor: Colors.clickableHover
        },
        '& .Mui-checked ~ .MuiFormControlLabel-label': {
            color: Colors.white,
            backgroundColor: Colors.clickable
        }
    }
})(FormControl);

export enum IndividualSortType {
    Rank = 'Rank',
    GlobalRank = 'Global Rank'
}

export class SidePanel extends Component<SidePanelProps & WithTranslation, SidePanelState> {
    private scrollToBottomRef: React.RefObject<HTMLDivElement>;

    constructor(props) {
        super(props);
        this.state = {
            showDetails: false,
            showAddLabel: false,
            showEditPerson: false,
            showAddPersonToWatchlist: false,
            showExportToZip: false,
            showGallery: false,
            isExportToZipLoading: false,
            selectedWatchlists: [],
            selectedTopologies: [],
            allTopologiesExceptCurrentTopology: [],
            individualSortType: IndividualSortType.Rank,
            isPersonToSplitSelected: false,
            selectedPersonAllImages: []
        };
        this.scrollToBottomRef = React.createRef();
    }

    componentDidMount() {
        const allTopologiesExceptCurrentTopology = this.props.topologies.filter(
            (topology) => topology.batchId !== this.props.caseId
        );

        this.setState({
            allTopologiesExceptCurrentTopology,
            selectedWatchlists: this.props.watchlistEntries.filter((watchlist) =>
                this.props.watchlistsIds.includes(watchlist.batchId)
            ),
            selectedTopologies: allTopologiesExceptCurrentTopology.filter((topology) =>
                this.props.commonTopologiesIds.includes(topology.batchId)
            )
        });
    }

    componentDidUpdate(prevProps: Readonly<SidePanelProps>, prevState: Readonly<SidePanelState>) {
        this.handleUrlChanges(prevProps);
        this.handlePersonChanges(prevProps);
    }

    handlePersonChanges(prevProps: Readonly<SidePanelProps>) {
        if (prevProps.selectedPerson !== this.props.selectedPerson) {
            this.setState({
                showAddPersonToWatchlist: false,
                showAddLabel: false,
                showEditPerson: false
            });
        }
    }

    handleUrlChanges(prevProps: Readonly<SidePanelProps>) {
        if (
            !_.isEqual(prevProps.commonTopologiesIds, this.props.commonTopologiesIds) ||
            !_.isEqual(prevProps.watchlistsIds, this.props.watchlistsIds)
        ) {
            this.setState({
                selectedWatchlists: this.props.watchlistEntries.filter((watchlist) =>
                    this.props.watchlistsIds.includes(watchlist.batchId)
                ),
                selectedTopologies: this.state.allTopologiesExceptCurrentTopology.filter((topology) =>
                    this.props.commonTopologiesIds.includes(topology.batchId)
                )
            });
        }
    }

    scrollToBottom = () => this.scrollToBottomRef.current?.scrollIntoView({ behavior: 'smooth' });

    loadSynapse = () => {
        const selectedTopologiesIds = this.state.selectedTopologies.map((topology) => topology.batchId);
        const selectedWatchlistsIds = this.state.selectedWatchlists.map((watchlist) => watchlist.batchId);

        const allBatchIds = selectedTopologiesIds.concat(this.props.synapseData.batchId);
        let seedIds = this.props.seedIds;

        const anySeedDoesNotExistsAnymore = this.props.currentSeeds?.some((seed) =>
            seed.batchIds.every((batchId) => !allBatchIds.includes(batchId))
        );
        if (anySeedDoesNotExistsAnymore) {
            const defaultPerson = TopologyViewService.getDefaultPerson(
                this.props.topology.topologyPersons,
                allBatchIds
            );
            seedIds = defaultPerson ? [defaultPerson] : [];
        }

        this.props.navigateSynapse(
            null,
            {
                watchlistIds: selectedWatchlistsIds,
                crossTopologyIds: selectedTopologiesIds,
                seedIds
            },
            true
        );
    };

    handleIndividualSortTypes = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            individualSortType: event.target.value as IndividualSortType
        });
    };

    handleLabelClose = (label?: Label) => {
        if (label) {
            ApiService.updateLabel(this.props.synapseData.batchId, this.props.selectedPerson.personUID, label)
                .then((res) => {
                    CommonService.sortLabels(res.data.labels);
                    this.props.optimisticSetLabelToAfterEdit(res.data);
                    this.setState({ showAddLabel: false });
                })
                .catch((error) => {
                    console.log(error);

                    toast.error(CommonService.getErrorMessage(error));
                });
        } else {
            this.setState({ showAddLabel: false });
        }
    };

    handleEditPersonClose = async (person?: PersonDescriptor) => {
        if (person) {
            try {
                const res = await ApiService.editPerson(this.props.caseId, person);
                this.props.optimisticUpdatePerson(res.data.result);
                toast.success(`Individual's details were updated successfully`);
            } catch (error) {
                toast.error(CommonService.getErrorMessage(error));
            }
        }
        this.setState({ showEditPerson: false });
    };

    isTopologiesDifferent = () =>
        !_.isEqual(
            this.state.selectedTopologies.map((topology) => topology.batchId).sort(),
            this.props.commonTopologiesIds.sort()
        );

    isWatchlistsDifferent = () =>
        !_.isEqual(
            this.state.selectedWatchlists.map((watchlist) => watchlist.batchId).sort(),
            this.props.watchlistsIds.sort()
        );

    handleMergeClick = () => this.props.setMergingMode(this.props.selectedPerson);

    convertMapOfImageKeyToAppearanceValue = (appearencesArray: PhotoAppearance[]): PhotoIdToAppearence =>
        appearencesArray.reduce((acc: PhotoIdToAppearence, currentAppearence: PhotoAppearance) => {
            if (!acc[currentAppearence.photoUID]) {
                acc[currentAppearence.photoUID] = [];
            }
            acc[currentAppearence.photoUID].push(currentAppearence);
            return acc;
        }, {});

    createPersonGalleryFromAllTopologies = async (): Promise<void> => {
        try {
            const allSelectedPersonImagesResponse = await ApiService.getUserPhotos(this.props.selectedPerson.personUID);
            const imageIds: string[] = [];
            const queryIds: Set<string> = new Set();
            allSelectedPersonImagesResponse.data.result.forEach(({ photoUID, batchIds }) => {
                imageIds.push(photoUID);
                batchIds.forEach((queryId) => {
                    queryIds.add(queryId);
                });
            });

            const personDataFromAllToplologies = await ApiService.fetchTopolgyDataByImageIds([...queryIds], imageIds);

            const { appearances: appearencesArray = [] } = personDataFromAllToplologies.data.result;
            const mapImageIdToAppearence = this.convertMapOfImageKeyToAppearanceValue(appearencesArray);

            this.setState({
                selectedPersonAllImages: synapsePhotoToImage(
                    personDataFromAllToplologies.data.result.photos,
                    this.props.topologyMetadata,
                    this.props.seedIds,
                    this.props.selectedPerson?.personUID,
                    mapImageIdToAppearence
                ),
                showGallery: true,
                isPersonToSplitSelected: true
            });
            return Promise.resolve();
        } catch (error) {
            toast.error(CommonService.getErrorMessage(error));
            return Promise.reject();
        }
    };

    handleAddLabelClick = () =>
        this.setState(
            {
                showAddLabel: !this.state.showAddLabel,
                showAddPersonToWatchlist: false,
                showEditPerson: false,
                showExportToZip: false
            },
            this.scrollToBottom
        );

    handleDetailsClick = () =>
        this.setState({
            showDetails: !this.state.showDetails,
            showAddLabel: false,
            showAddPersonToWatchlist: false,
            showEditPerson: false,
            showExportToZip: false
        });

    handleAddPersonToWatchlistClick = () =>
        this.setState(
            {
                showAddPersonToWatchlist: !this.state.showAddPersonToWatchlist,
                showAddLabel: false,
                showEditPerson: false,
                showExportToZip: false
            },
            this.scrollToBottom
        );

    handleEditPersonProperties = () =>
        this.setState(
            {
                showAddPersonToWatchlist: false,
                showAddLabel: false,
                showExportToZip: false,
                showEditPerson: !this.state.showEditPerson
            },
            this.scrollToBottom
        );

    handleExportToZip = () => {
        this.setState({
            showExportToZip: true,
            showAddPersonToWatchlist: false,
            showAddLabel: false,
            showEditPerson: false
        });
    };

    createBodyForExportRequest = (exportMode: ExportDialogMode) => {
        if (this.props.isMultipleSelectedConnectionsMode) {
            const personsArray = this.props.selectedMultiplePersons.map((person) => person.personUID);
            return {
                userIds: personsArray,
                queryId: exportMode === ExportDialogMode.Current ? this.props.caseId : null
            };
        } else {
            return {
                userIds: [this.props.selectedPerson.personUID],
                queryId: exportMode === ExportDialogMode.Current ? this.props.caseId : null
            };
        }
    };

    handleExportToZipProcessing = async (exportMode: ExportDialogMode) => {
        if (!exportMode) {
            return;
        }

        this.setState({ isExportToZipLoading: true });

        try {
            const reqBody: ExportPersonsRequest = this.createBodyForExportRequest(exportMode);
            const exportResponse = await ExportService.exportPersonToZip(reqBody);

            const link = document.createElement('a');
            link.href = exportResponse.data;
            link.setAttribute('download', '');
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.log(error);
        }

        this.setState({ isExportToZipLoading: false, showExportToZip: false });
    };

    handleExportToZipCancel = () => {
        this.setState({ isExportToZipLoading: false, showExportToZip: false });
    };

    changeSeed = (person: PersonDescriptor) => this.changeSeedById(person.personUID);

    changeSeedById = (personId: string) => {
        if (!this.props.synapseMetadata.isGroupTopology && personId === this.props.seedIds[0]) {
            return;
        }
        this.props.changeSeed(personId);
    };

    handleFilterClose = (filters: ServerFilters) => {
        if (filters) {
            this.props.updateServerFilters(filters);
        }
        this.props.toggleFiltersPanel(false);
    };

    buildImages = (photosUIDs: SynapsePhoto[]): Image[] => {
        return photosUIDs.map((photo) => ({
            id: photo.photoUID,
            url: `/api/photo/${photo.jFileId}`,
            name: photo.customFileName || photo.fileName,
            imageSourceUrl: GalleryService.getImageSourceUrl(photo),
            shapes: GalleryService.convertAppearancesToShapes(
                this.props.topologyMetadata.photoUIDtoPhotoAppearances[photo.photoUID],
                this.props.topologyMetadata.personUIDtoPerson,
                this.props.seedIds,
                this.props.selectedPerson?.personUID
            ),
            extraText: GalleryService.getPhotoDateText(photo.creationDate, photo.timestamp),
            metadata: GalleryService.getPhotoMetaData(photo),
            source: photo.photoType,
            flagged: photo.flagged,
            thumbnail: photo.thumbnails?.[0] ? GalleryService.convertThumbnail(photo.thumbnails[0]) : null,
            faceRecognitionEnabled: photo.faceRecognitionEnabled,
            image2TextEnabled: photo.image2TextEnabled,
            objectDetectionEnabled: photo.objectDetectionEnabled,
            ocrEnabled: photo.ocrEnabled,
            explicitContentEnabled: photo.explicitContentEnabled,
            landmarksEnabled: photo.landmarksEnabled,
            similarImagesEnabled: photo.similarImagesEnabled,
            detectedLandmarks: photo.detectedLandmarks
        }));
    };

    getGalleryPhotos = (): Image[] => {
        const selectedConnectionPhotoIds = new Set(this.props.synapseMetadata.filteredSelectedPhotoUIDs);
        const filteredPhotos = this.props.topology.topologyPhotos.filter((photo) =>
            selectedConnectionPhotoIds.has(photo.photoUID)
        );
        return synapsePhotoToImage(
            filteredPhotos,
            this.props.topologyMetadata,
            this.props.seedIds,
            this.props.selectedPerson?.personUID
        );
    };

    handleSeeSelectedPhotosClick = () => {
        if (this.props.synapseMetadata.filteredSelectedPhotoUIDs.length > 0) {
            this.setState({ showGallery: true });
        }
    };

    closeGallery = () => this.setState({ showGallery: false, isPersonToSplitSelected: false });

    handleGalleryDoubleClick = (id: string) => {
        this.changeSeedById(id);
        this.closeGallery();
    };

    getPopupTitle = () => {
        if (this.state.isPersonToSplitSelected) {
            const name = this.props.selectedPerson?.labels?.length
                ? this.props.selectedPerson?.labels[0].label
                : this.props.t('side_panel.individual');
            return this.props.t('side_panel.split') + ' ' + name;
        }
        if (
            !this.props.synapseMetadata.isGroupTopology &&
            this.props.seedIds[0] === this.props.selectedPerson.personUID
        ) {
            return this.props.t('side_panel.seed_images');
        }
        return this.props.t('side_panel.selected_connection_images');
    };

    // This sorting method created in order to place on top of gallery all
    // the images that include more then one appearences of personToSplitUID
    sortIncludingMultipleFaces(images: Image[], personToSplitUID: string): Image[] {
        return images.sort((a) => (a.shapes.filter((shape) => shape.id === personToSplitUID).length > 1 ? -1 : 0));
    }

    getGallery() {
        if (this.state.isPersonToSplitSelected) {
            return this.sortIncludingMultipleFaces(
                this.state.selectedPersonAllImages,
                this.props.selectedPerson.personUID
            );
        }
        return this.getGalleryPhotos();
    }

    onConfirmSplitSuccess = async (): Promise<void> => {
        await this.createPersonGalleryFromAllTopologies();
        this.props.fetchTopology();
        return Promise.resolve();
    };

    onSaveOrCancelWatchlist = () => {
        this.setState({ showAddPersonToWatchlist: false });
        this.props.fetchTopology();
    };

    getSingleOrMultiplePanelComponent = () => {
        if (this.props.isMultipleSelectedConnectionsMode) {
            return (
                <SidePanelMultipleSelect
                    onDeleteClick={this.props.onDeleteMultiplePersons}
                    onMergeClick={this.handleMergeClick}
                    onAddPersonToWatchlistClick={this.handleAddPersonToWatchlistClick}
                    onExportToZip={this.handleExportToZip}
                    showAddPersonToWatchlist={this.state.showAddPersonToWatchlist}
                    showExportToZip={this.state.showExportToZip}
                    scrollToBottomRef={this.scrollToBottomRef}
                    isExportToZipLoading={this.state.isExportToZipLoading}
                    handleExportToZipProcessing={this.handleExportToZipProcessing}
                    caseName={this.props.currentTopology?.name}
                    handleExportToZipCancel={this.handleExportToZipCancel}
                    onSaveOrCancelWatchlist={this.onSaveOrCancelWatchlist}
                />
            );
        }
        if (!!this.props.selectedPerson) {
            return (
                <SidePanelSingleSelect
                    handleSeeSelectedPhotosClick={this.handleSeeSelectedPhotosClick}
                    onDeleteClick={this.props.onDeleteMultiplePersons}
                    onDetailsClick={this.handleDetailsClick}
                    setViewMode={this.props.setViewMode}
                    onMergeClick={this.handleMergeClick}
                    onSplitClick={this.createPersonGalleryFromAllTopologies}
                    doMerge={this.props.doMerge}
                    onAddLabelClick={this.handleAddLabelClick}
                    onAddPersonToWatchlistClick={this.handleAddPersonToWatchlistClick}
                    onEditPersonProperties={this.handleEditPersonProperties}
                    onExportToZip={this.handleExportToZip}
                    showAddLabel={this.state.showAddLabel}
                    showAddPersonToWatchlist={this.state.showAddPersonToWatchlist}
                    showEditPerson={this.state.showEditPerson}
                    showExportToZip={this.state.showExportToZip}
                    showDetails={this.state.showDetails}
                    handleLabelClose={this.handleLabelClose}
                    scrollToBottomRef={this.scrollToBottomRef}
                    isExportToZipLoading={this.state.isExportToZipLoading}
                    handleEditPersonClose={this.handleEditPersonClose}
                    handleExportToZipProcessing={this.handleExportToZipProcessing}
                    handleExportToZipCancel={this.handleExportToZipCancel}
                    onSaveOrCancelWatchlist={this.onSaveOrCancelWatchlist}
                    onCloseIndividualDetails={() => this.setState({ showDetails: false })}
                    caseName={this.props.currentTopology?.name}
                    mergePersonTarget={this.props.mergePersonTarget}
                    personsToMerge={this.props.personsToMerge}
                />
            );
        }
    };

    // ----------- disableSimilarImage props explanation -----------
    // quick fix, similar image  functionality is not working when
    // the gallery is seed images, so in order to do a quick fix, we
    // disable similar image in seed images
    // NEED TO BE REMOVE ASAP

    render() {
        return (
            <div className='side-panel-container'>
                <Link style={{ textDecoration: 'none' }} to='/cases' className='logo-container'>
                    {!authService.hasPermissions(Permissions.show_white_label) && <Logo />}
                </Link>
                <StyledDrawer
                    variant='persistent'
                    open={this.props.isFiltersOpen}
                    BackdropProps={{ invisible: true }}
                    anchor='left'
                    onClose={() => this.props.toggleFiltersPanel(false)}>
                    <SideFilters onClose={this.handleFilterClose} initialValues={this.props.serverFilters} />
                </StyledDrawer>
                {this.state.showGallery && (
                    <TopologyPopupGalleryWrapper
                        title={this.getPopupTitle()}
                        onDoubleClick={this.handleGalleryDoubleClick}
                        onClose={this.closeGallery}
                        images={this.getGallery()}
                        personToSplit={this.state.isPersonToSplitSelected ? this.props.selectedPerson : null}
                        onConfirmSplitSuccess={this.onConfirmSplitSuccess}
                        disableSimilarImage
                    />
                )}
                <div className='after-logo'>
                    <div className='side-panel-section side-panel-search'>
                        <div className='search-header-container'>
                            <div className='search-header'>{this.props.t('side_panel.search')}</div>
                            {!this.props.filtersButtonHidden && (
                                <Button
                                    data-test-id='open-filter-panel'
                                    style={{ minWidth: 0 }}
                                    onClick={() => this.props.toggleFiltersPanel(true)}
                                    size='small'
                                    variant='outlined'
                                    className='search-header-filters'>
                                    <CustomSVGIcon type={svgIcons.filter} size={20} />
                                    <Typography color='primary' style={{ fontSize: 14 }}>
                                        {this.props.activeServerFiltersCount
                                            ? `(${this.props.activeServerFiltersCount})`
                                            : null}
                                    </Typography>
                                </Button>
                            )}
                        </div>

                        <SearchSectionContainer
                            onDoubleClick={this.changeSeed}
                            doSelectPerson={this.props.doSelectPerson}
                        />
                    </div>
                    <div className='side-panel-section side-panel-individuals-sorting'>
                        <StyledFormControl className='individuals-sorting-switch'>
                            <FormLabel className='side-panel-subheader individuals-sorting-switch-label'>
                                {this.props.t('side_panel.sort_by')}
                            </FormLabel>
                            <RadioGroup
                                aria-label='Expanded Search Type'
                                name='expanded-search-type-radio-group'
                                value={this.state.individualSortType}
                                onChange={this.handleIndividualSortTypes}
                                className='individuals-sorting-switch-group'
                                row>
                                <FormControlLabel
                                    value={IndividualSortType.Rank}
                                    control={<Radio />}
                                    label={this.props.t('side_panel.case_rank')}
                                />
                                <FormControlLabel
                                    value={IndividualSortType.GlobalRank}
                                    control={<Radio />}
                                    label={this.props.t('side_panel.system_rank')}
                                />
                            </RadioGroup>
                        </StyledFormControl>
                    </div>
                    <div className='side-panel-section side-panel-individuals-photos'>
                        <KeyIndividuals
                            individualSortType={this.state.individualSortType}
                            personsInTopology={this.props.synapseData.topologyPersons}
                            onDoubleClick={this.changeSeed}
                            doSelectPerson={this.props.doSelectPerson}
                            resetViewMode={this.props.resetViewMode}
                            mergePersonTarget={this.props.mergePersonTarget}
                            personsToMerge={this.props.personsToMerge}
                        />
                        {this.getSingleOrMultiplePanelComponent()}
                    </div>
                    <div className='side-panel-section side-panel-watchlist'>
                        <div className='side-panel-section-header'>{this.props.t('side_panel.watchlists')}</div>
                        {this.props.watchlistEntries?.length > 0 && (
                            <div>
                                <SidePanelMultiSelect
                                    onClose={() => (this.isWatchlistsDifferent() ? this.loadSynapse() : null)}
                                    onAutoCompleteChange={(e, selectedWatchlists, reason) =>
                                        this.setState({ selectedWatchlists }, () =>
                                            reason === 'clear' || reason === 'delete-chip' ? this.loadSynapse() : null
                                        )
                                    }
                                    options={this.props.watchlistEntries}
                                    value={this.state.selectedWatchlists}
                                    label={this.props.t('side_panel.watchlists')}
                                    dataTestId='select-watchlists'
                                />
                            </div>
                        )}
                        {this.props.watchlistEntries?.length > 0 &&
                            this.props.synapseData.watchLists.map((wlp) => {
                                const watchlist = this.props.watchlistEntries.find(
                                    (_) => _.batchId === wlp.watchListId
                                );

                                return watchlist ? (
                                    <div key={watchlist.batchId} className='watchlist-results'>
                                        <Typography
                                            color='textSecondary'
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: 12,
                                                textDecoration: 'underline',
                                                wordBreak: 'break-word'
                                            }}>
                                            {this.props.t('side_panel.found_in')} "{watchlist.name}" (
                                            {wlp.personsIds.length})
                                        </Typography>

                                        <VirtualAvatarList
                                            maxRows={2}
                                            onDoubleClick={this.changeSeed}
                                            doSelectPerson={this.props.doSelectPerson}
                                            persons={this.props.synapseData.topologyPersons.filter((person) =>
                                                wlp.personsIds.includes(person.personUID)
                                            )}
                                        />
                                    </div>
                                ) : null;
                            })}
                    </div>

                    <div className='side-panel-section side-panel-cases'>
                        <div className='side-panel-section-header'>
                            {this.props.t('side_panel.cases')}
                            {this.state.allTopologiesExceptCurrentTopology?.length !==
                                this.state.selectedTopologies?.length &&
                                authService.hasPermissions(Permissions.select_all_topologies) && (
                                    <Typography
                                        onClick={() =>
                                            this.setState(
                                                {
                                                    selectedTopologies: this.state.allTopologiesExceptCurrentTopology
                                                },
                                                () => this.loadSynapse()
                                            )
                                        }
                                        className='text-button'>
                                        {this.props.t('side_panel.select_all')}
                                    </Typography>
                                )}
                        </div>
                        <div>
                            <SidePanelMultiSelect
                                onClose={() => (this.isTopologiesDifferent() ? this.loadSynapse() : null)}
                                onAutoCompleteChange={(e, selectedTopologies, reason) => {
                                    this.setState({ selectedTopologies }, () =>
                                        reason === 'clear' || reason === 'delete-chip' ? this.loadSynapse() : null
                                    );
                                }}
                                options={this.state.allTopologiesExceptCurrentTopology}
                                value={this.state.selectedTopologies}
                                label={this.props.t('side_panel.cases')}
                                dataTestId='select-cases'
                            />
                        </div>
                        {this.props.synapseData.commonPersons.map((common) => {
                            const topology = this.props.topologies.find(
                                (topology) => topology.batchId === common.topologyId
                            );
                            return topology ? (
                                <div className='cross-topology-results' key={topology.batchId}>
                                    <Typography color='textSecondary' className='side-panel-subheader'>
                                        {this.props.t('side_panel.found_in')} "{topology.name}" (
                                        {common.personsIds.length})
                                    </Typography>
                                    <VirtualAvatarList
                                        maxRows={2}
                                        rowHeight={46}
                                        onDoubleClick={this.changeSeed}
                                        doSelectPerson={this.props.doSelectPerson}
                                        persons={this.props.synapseData.topologyPersons
                                            .filter((person) => common.personsIds.includes(person.personUID))
                                            .sort((personA, personB) => {
                                                const bothHaveLabel = personA.labels?.length && personB.labels?.length;
                                                const PersonAHasLabels = personA.labels?.length > 0;
                                                return bothHaveLabel ? 0 : PersonAHasLabels ? -1 : 1;
                                            })}
                                    />
                                </div>
                            ) : null;
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    topology: state.topology.data,
    topologyMetadata: state.topology.topologyMetadata,
    synapseMetadata: state.topology.synapseMetadata,
    synapse: state.topology.currentSynapse,
    synapseData: state.topology.data,
    selectedPerson: state.topology.selectedConnection,
    selectedMultiplePersons: state.topology.selectedMultipleConnections,
    currentSeeds: state.topology.currentSeeds,
    serverFilters: state.filters.serverFilters,
    topologies: getFinishedTopologies(state),
    watchlistEntries: getFinishedWatchlists(state),
    watchlistsIds: TopologyRouterSelectors.getWatchlistIds(state),
    commonTopologiesIds: TopologyRouterSelectors.getCommonTopologies(state),
    isTnt: TopologyRouterSelectors.isTnt(state),
    caseId: TopologyRouterSelectors.getBatchId(state),
    seedIds: TopologyRouterSelectors.getSeedIds(state) || [],
    activeServerFiltersCount: activeServerFiltersCount(state),
    isFiltersOpen: state.topology.isFiltersOpen,
    isMultipleSelectedConnectionsMode: state.topology.isMultipleSelectedConnectionsMode,
    currentTopology: state.topologies.currentTopology
});

const mapDispatchToProps = {
    changeSeed,
    navigateSynapse,
    updateServerFilters,
    toggleFiltersPanel,
    setShowLoader,
    fetchTopology
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    withTranslation(['topologyView', 'filters'], { withRef: true })(SidePanel)
);
