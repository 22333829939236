import { Grid } from '@material-ui/core';
import { push } from 'connected-react-router';
import * as d3 from 'd3';
import { Simulation, SimulationNodeDatum } from 'd3';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import CommonService from '../../../services/commonService';
import { RootState } from '../../../store';
import { changeSeed, TopologyRouterSelectors } from '../../../store/router/topologyActions';
import { Colors } from '../../colors';
import { AvatarColor, svgIcons } from '../../common/entities/enums';
import CustomSVGIcon from '../../common/misc/CustomSvgIcon';
import SynapseHistoryService from '../synapseHistoryService';
import { LabelOrigin, PersonDescriptor, Synapse } from '../VisionSynapse';
import { withTranslation, WithTranslation } from 'react-i18next';
import { renderToString } from 'react-dom/server';

const width = window.innerWidth * 0.8;
const height = window.innerHeight * 0.9;
const NODE_DIAMETER = 50;

interface Node {
    id: string;
    x: number | null;
    y: number | null;
    strength: number;
    label: string | null;
    isSeed: boolean;
    representingPhotoId: string;
    isSelected?: boolean;
}

interface Edge {
    source: string;
    target: string;
    strength: number;
}

interface Props extends ReduxProps<typeof mapDispatchToProps, typeof mapStateToProps> {
    doSelectPerson: (person: PersonDescriptor, ctrlKey: boolean) => void;
}

interface State {
    nodes: Node[];
}

class GraphVizView extends Component<Props & WithTranslation, State> {
    private container: React.RefObject<any>;
    private svg: d3.Selection<SVGElement, any, any, any>;
    private d3Container: d3.Selection<SVGElement, any, any, any>;
    private d3force: Simulation<SimulationNodeDatum, undefined>;

    constructor(props) {
        super(props);
        this.container = React.createRef();
        this.d3force = d3.forceSimulation();

        this.state = {
            nodes: []
        };
    }

    render() {
        return (
            <Grid style={{ height: '100%' }} container>
                {SynapseHistoryService.isNotOnRootTopology() && (
                    <div
                        onClick={this.handleBack}
                        style={{ position: 'absolute', margin: '10px 30px', cursor: 'pointer', zIndex: 1 }}
                        className='back-btn flex-align-center'>
                        <CustomSVGIcon fillColor={Colors.clickable} type={svgIcons.back} size={20} />
                        <span>{this.props.t('graph_viz_view.back')}</span>
                    </div>
                )}
                <Grid item className='d3-container' style={{ flex: 1, overflow: 'hidden' }} ref={this.container} />
            </Grid>
        );
    }

    componentDidMount() {
        this.createElements();
        this.updateGraphViz();
    }

    componentWillUnmount() {
        SynapseHistoryService.removeAllRoutes();
    }

    handleBack = () => {
        const route = SynapseHistoryService.removeLastRoute();

        this.props.push(route);
    };

    shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>) {
        return (
            this.state !== nextState ||
            nextProps.topology !== this.props.topology ||
            nextProps.synapse !== this.props.synapse ||
            nextProps.selectedPersonId !== this.props.selectedPersonId ||
            !_.isEqual(nextProps.clientFilters, this.props.clientFilters)
        );
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        const isFilterDifferent = !_.isEqual(prevProps.clientFilters, this.props.clientFilters);
        if (this.isSynapseDifferent(prevProps) || isFilterDifferent) {
            this.svg.remove();
            this.createElements();
            this.updateGraphViz();
        }

        if (prevProps.selectedPersonId !== this.props.selectedPersonId && this.props.selectedPersonId) {
            this.updateSelectedConnectionNodeColor();
        }
    }

    getNodeColor = (node: Node) => {
        if (node.isSeed) {
            return AvatarColor.seed;
        }
        if (node.id === this.props.selectedPersonId) {
            return AvatarColor.selectedConnection;
        }
        if (this.props.isTnt && this.props.synapseMetadata.identifiedPeopleIdsInTnt.indexOf(node.id) !== -1) {
            return AvatarColor.identifiedTnt;
        }
        return Colors.individualsOutlineGrey;
    };

    updateSelectedConnectionNodeColor = () =>
        this.d3Container
            .selectAll('g')
            .select('circle')
            .attr('stroke', (node: Node) => this.getNodeColor(node))
            .attr('stroke-width', (d: Node) =>
                d.id === this.props.selectedPersonId ? '4px' : d.isSeed ? '2px' : '1px'
            );

    private isSynapseDifferent(prevProps: Readonly<Props>) {
        return (
            !_.isEqual(prevProps.synapse, this.props.synapse) ||
            prevProps.synapse.personsInSynapse !== this.props.synapse.personsInSynapse
        );
    }

    private updateGraphViz() {
        const { nodes, edges } = this.getNodesAndEdges(this.props.synapse);

        this.d3Container
            .selectAll('.node')
            .data(nodes, (node: Node) => node.id)
            .enter()
            .append('g')
            .call(this.enterNode)
            .call(
                d3
                    .drag()
                    .on('start', () => {
                        d3.event.sourceEvent.stopPropagation();
                        this.d3force.on('tick', null).stop();
                    })
                    .on('drag', (d: any) => {
                        d3.event.sourceEvent.stopPropagation();
                        d.px += d3.event.dx;
                        d.py += d3.event.dy;
                        d.x += d3.event.dx;
                        d.y += d3.event.dy;
                        // Update node and link on drag and move.
                        this.d3Container.selectAll('.node').call(this.updateNode);
                        this.d3Container.selectAll('.link').call(this.updateLink);
                    })
            )
            .exit()
            .remove();

        const d3Nodes = this.d3Container.selectAll('.node');
        d3Nodes.call(this.updateNode);

        // Create image defs
        const defs = d3.select('defs');

        d3Nodes.each((d: Node) => {
            defs.append('pattern')
                .attr('id', 'img_' + d.id)
                .attr('height', 1)
                .attr('width', 1)
                .append('image')
                .attr('preserveAspectRatio', 'xMaxYMid slice')
                .attr('height', () => {
                    return d.isSeed ? NODE_DIAMETER * 2 : NODE_DIAMETER;
                })
                .attr('width', () => {
                    return d.isSeed ? NODE_DIAMETER * 2 : NODE_DIAMETER;
                })
                .attr('xlink:href', '/api/photo/' + d.representingPhotoId);
        });

        this.d3Container
            .selectAll('.link')
            .data(edges, (link: Edge) => link.target)
            .enter()
            .insert('line', '.node')
            .call(this.enterLink)
            .exit()
            .remove();

        this.d3Container.selectAll('.link').call(this.updateLink);

        this.d3force
            .nodes(nodes)
            .force(
                'link',
                d3
                    .forceLink<Node, Edge>(edges)
                    .id((n: Node) => n.id)
                    .distance(20)
            )
            .force('charge', d3.forceManyBody().strength(-2000))
            .force('center', d3.forceCenter(width / 2, height / 2))
            .force(
                'collision',
                d3.forceCollide().radius(function (d) {
                    return 20;
                })
            )
            .force('forceX', d3.forceX())
            .force('forceY', d3.forceY())
            //.force("r", d3.forceRadial((d: Node) => d.isSeed ? NODE_DIAMETER : NODE_DIAMETER / 2))
            .on('tick', () => {
                // after force calculation starts, call updateGraph
                // which uses d3 to manipulate the attributes,
                // and React doesn't have to go through lifecycle on each tick
                if (this.d3force.alpha() > 0.01) {
                    this.d3Container.call(this.updateGraph);
                } else {
                    this.d3force.stop();
                    // this.setState({
                    //     isLoading: false
                    // });
                }
            });
        // make svg responsive
        this.responsivefy(this.svg, d3.select(this.container.current));
        this.d3Container.call(this.updateGraph);

        this.d3force.alpha(1).restart();
    }

    private getNodesAndEdges = (synapse: Synapse) => {
        const existingNodesById: { [s: string]: Node } = this.state.nodes?.reduce((out, node) => {
            out[node.id] = node;
            return out;
        }, {});

        const allNodesInSynapse: Node[] = synapse.personsInSynapse.map((person) => {
            const existing = existingNodesById[person.personUID];
            if (!!existing) {
                existing.strength = this.props.synapseMetadata.connectionStrengthByPersonUID[person.personUID];
                existing.label = CommonService.getHighestPriority(person.labels);
                existing.isSeed = this.props.synapse.seedIds.indexOf(person.personUID) !== -1;
                existing.representingPhotoId = person.representingPhotoId;
                return existing;
            } else {
                return {
                    id: person.personUID,
                    strength: this.props.synapseMetadata.connectionStrengthByPersonUID[person.personUID],
                    label: CommonService.getHighestPriority(person.labels),
                    isSeed: this.props.synapse.seedIds.indexOf(person.personUID) !== -1,
                    representingPhotoId: person.representingPhotoId,
                    x: width / 2 + Math.random() * 200 - 100,
                    y: height / 2 + Math.random() * 200 - 100
                } as Node;
            }
        });
        this.setState({ nodes: allNodesInSynapse });

        const visibleNodes = allNodesInSynapse.filter(
            (node) => this.props.synapseMetadata.filteredPersonUIDsInSynapse.indexOf(node.id) !== -1
        );

        const nodesSet = new Set<string>(visibleNodes.map((node) => node.id));
        const edges: Edge[] = synapse.connections
            .filter((connection) => nodesSet.has(connection.fromPersonUID) && nodesSet.has(connection.toPersonUID))
            .map(
                (connection) =>
                    ({
                        source: connection.fromPersonUID,
                        target: connection.toPersonUID,
                        strength: this.props.synapseMetadata.connectionStrengthByPersonUID[connection.toPersonUID]
                    } as Edge)
            );

        // Make sure in d3 nodes with labels will be on top. (have the equal of being on top of everything using z-index)
        visibleNodes.sort((node) => (node.isSeed || node.label ? 1 : -1));
        return { nodes: visibleNodes, edges };
    };

    private createElements = () => {
        this.svg = d3.select(this.container.current).append('svg');
        this.svg.attr('id', 'graphSvg');
        this.svg.attr('width', 400);
        this.svg.attr('height', 400);
        this.d3Container = this.svg.append('g');
        this.svg.append('defs');
    };

    private enterNode = (selection: d3.Selection<SVGGElement, Node, SVGElement, any>) => {
        const _d3 = d3;
        const props = this.props;
        const tooltip = d3.select('.d3-container').append('div').classed('d3-tooltip', true);
        const buildSvg = (type: svgIcons, size: number, className: string, fillColor?: string) => {
            return renderToString(
                <CustomSVGIcon
                    type={type}
                    size={size}
                    customClass={`d3-tooltip-icon ${className}`}
                    fillColor={fillColor ? fillColor : null}
                />
            );
        };

        const tooltipContent = (node) => {
            const text = node.isSeed
                ? props.t('graph_viz_view.relationships_number') +
                  '<span class="d3-tooltip-bold">' +
                  (props.synapseMetadata.filteredPersonUIDsInSynapse.length - 1) +
                  '</span>'
                : props.t('graph_viz_view.relationships_strength') +
                  '<span class="d3-tooltip-bold">' +
                  node.strength.toFixed(0) +
                  '</span>';

            let content = '<div class="d3-tooltip-content">';
            content += buildSvg(svgIcons.union, 14, 'before-text', Colors.black);
            content += text;
            content += '</div>';
            const nodeLabels = props.topologyMetadata.personUIDtoPerson[node.id]?.labels;
            if (!!nodeLabels?.length) {
                let labelText =
                    '<div class="d3-tooltip-content">' +
                    buildSvg(svgIcons.tag, 16, 'before-text', Colors.black) +
                    '<span class="d3-tooltip-text">' +
                    props.t('graph_viz_view.labels_from') +
                    '</span>';
                const networkLabels = nodeLabels.filter((label) => label.origin === LabelOrigin.Social);
                if (!!networkLabels.length) {
                    const uniqueNetworks = [...new Set(networkLabels.map((label) => label.metadata.network))];
                    for (const network of uniqueNetworks) {
                        labelText += buildSvg(svgIcons[network.toLowerCase()], 16, 'label-icon');
                    }
                }
                if (nodeLabels.find((label) => label.origin === LabelOrigin.Watchlist)) {
                    labelText += buildSvg(svgIcons.binoculars, 16, 'label-icon');
                }
                if (nodeLabels.find((label) => label.origin === LabelOrigin.System)) {
                    labelText += buildSvg(svgIcons.group, 16, 'label-icon');
                }
                content += labelText + '</div>';
            }
            return content;
        };

        selection.classed('node', true).attr('id', (d: Node) => d.id);
        selection
            .append('circle')
            .attr('fill', (d: Node) => 'url(#img_' + d.id + ')')
            .attr('stroke', (node: Node) => this.getNodeColor(node))
            .attr('stroke-width', (d: Node) => (d.isSeed ? '2px' : '1px'))
            .attr('r', (d: Node) => (d.isSeed ? NODE_DIAMETER : NODE_DIAMETER / 2))
            .on('dblclick', (node: Node) => {
                d3.selectAll('.d3-tooltip').remove();
                _d3.event.stopPropagation();
                if (node.isSeed && !props.synapseMetadata.isGroupTopology) {
                    return;
                }
                tooltip.style('opacity', 0);
                this.props.changeSeed(node.id);
            })
            .on('click', (node: Node) => {
                this.props.doSelectPerson(
                    {
                        personUID: node.id,
                        label: node.label,
                        score: node.strength,
                        representingPhotoId: node.representingPhotoId
                    } as PersonDescriptor,
                    d3.event.ctrlKey || d3.event.metaKey
                );
            })
            .on('mouseover', (node: Node) => {
                if ((props.isTnt || props.synapseMetadata.isGroupTopology) && node.isSeed) {
                    return;
                }
                tooltip.style('opacity', 1); // show the tooltip
                tooltip
                    .html(tooltipContent(node))
                    .style('left', d3.event.offsetX + 5 + 'px')
                    .style('top', d3.event.offsetY + 5 + 'px');
            })
            .on('mouseleave', () => {
                tooltip.style('opacity', 0);
            });

        selection.classed('node', true).attr('id', (d) => d.id);

        // Append label to nodes
        selection
            .append('text')
            .text((node: Node) => node.label)
            .attr('width', 20)
            .attr('height', 18)
            .style('fill', 'white')
            .style('font-size', 12)
            .style('stroke-width', '0px')
            .style('text-anchor', 'middle')
            .attr('y', (node: Node) => (node.isSeed ? 65 : 40))
            .attr('x', (node: Node) => 0);
    };

    private updateNode = (selection: d3.Selection<SVGGElement, Node, SVGElement, any>) => {
        // const that = this;
        selection
            // .attr("fx", (d: Node) => d.x)PhotoNodeType
            // .attr("fy", (d: Node) => d.y)
            .attr('transform', (d: Node) => 'translate(' + d.x + ',' + d.y + ')');
        // .attr('display', (d: Node) => (that.props.synapseMeta.filteredPersonUIDsInSynapse.has(d.id) ? 'inherit' : 'none'))
        // .classed('selected', d => {
        //return self.props.selectedNode && self.props.selectedNode.id === d.id;
        // })
        // .classed('top-connection', d => {
        //return d.topConnection;
        // });

        this.reDrawLabel(this.d3Container.selectAll('.node'));
    };

    reDrawLabel(selection: d3.Selection<SVGGElement, Node, SVGElement, any>) {
        selection.select('text').text((d) => d.label);
    }

    private enterLink = (selection: d3.Selection<SVGGElement, Edge, SVGElement, any>) => {
        selection
            .classed('link', true)
            .style('stroke-width', (d: Edge) => {
                return d.strength * 1;
            })
            .style('stroke', (d: Edge) => CommonService.shadeColor('#003bef', -((10 - d.strength) * 6)));
    };

    private updateLink = (selection: d3.Selection<SVGGElement, any, SVGElement, any>) => {
        selection
            .attr('x1', (e) => e.source.x)
            .attr('y1', (e) => e.source.y)
            .attr('x2', (e) => e.target.x)
            .attr('y2', (e) => e.target.y);
    };

    private updateGraph = (selection: d3.Selection<any, any, any, any>) => {
        selection.selectAll('.node').call(this.updateNode);
        selection.selectAll('.link').call(this.updateLink);
    };

    private responsivefy = (svg: d3.Selection<SVGElement, any, any, any>, container) => {
        // container will be the DOM element
        // that the svg is appended to
        // we then measure the container
        // and find its aspect ratio

        // set viewBox attribute to the initial size
        // control scaling with preserveAspectRatio
        // resize svg on initial page load
        svg
            //.attr('viewBox', `0 0 ${width} ${height}`)
            .attr('preserveAspectRatio', 'xMinYMid')
            .call(resize);

        // add a listener so the chart will be resized
        // when the window resizes
        // multiple listeners for the same event type
        // requires a namespace, i.e., 'click.foo'
        // api docs: https://goo.gl/F3ZCFr
        d3.select(window).on('resize.' + container.attr('id'), resize);

        // this is the code that resizes the chart
        // it will be called on load
        // and in response to window resizes
        // gets the width of the container
        // and resizes the svg to fill it
        // while maintaining a consistent aspect ratio

        // setup zooming
        const zoom = d3
            .zoom()
            .scaleExtent([0.1, 10])
            .touchable(true)
            .filter(() => {
                // allow touch events on PC
                return true;
            })
            .on('zoom', () => {
                g.attr('transform', d3.event.transform);
                // resize();
            });

        const g = d3.select('#graphSvg > g');
        svg.call(zoom);

        function resize() {
            const w = parseInt(container.style('width'));
            const h = parseInt(container.style('height'));
            svg.attr('width', w);
            svg.attr('height', h);
            //svg.attr('viewBox', `0 0 ${w} ${h}`);
        }
    };
}

const mapStateToProps = (state: RootState) => ({
    topology: state.topology.data,
    topologyMetadata: state.topology.topologyMetadata,
    synapseMetadata: state.topology.synapseMetadata,
    synapse: state.topology.currentSynapse,
    selectedPersonId: state.topology.selectedConnection?.personUID,
    clientFilters: state.filters.clientFilters,
    isTnt: TopologyRouterSelectors.isTnt(state)
});

const mapDispatchToProps = { push, changeSeed };

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('topologyView')(GraphVizView));
