import { Avatar, Grid } from '@material-ui/core';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import authService from '../../services/authService';
import CommonService from '../../services/commonService';
import { Permissions } from '../../shared/model/Permissions';
import { RootState } from '../../store';
import { Colors } from '../colors';
import { PersonDescriptor } from '../topologyview/VisionSynapse';
import { AvatarColor, svgIcons } from './entities/enums';
import CustomSVGIcon from './misc/CustomSvgIcon';

interface VvAvatarProps extends ReturnType<typeof mapStateToProps> {
    person: PersonDescriptor;
    onDoubleClick?: (person: PersonDescriptor) => void;
    onClick?: (person: PersonDescriptor, ctrlKey: boolean) => void;
    borderColor?: string;
    style?: React.CSSProperties;
    mergePersonTarget?: PersonDescriptor;
    personsToMerge?: PersonDescriptor[];
}

const VvAvatar: React.FC<VvAvatarProps> = ({
    person,
    borderColor,
    onClick,
    onDoubleClick,
    watchlists,
    style,
    selectedMultipleConnections,
    mergePersonTarget,
    personsToMerge
}) => {
    const allLabels = CommonService.getAllLabelsForToolTip(person.labels, watchlists);

    const selectedConnection = useSelector((state: RootState) => state.topology.selectedConnection);
    const seedIds = useSelector((state: RootState) => state.topology.currentSynapse?.seedIds || []);
    const multipleConnectionsIds = selectedMultipleConnections.map((connection) => connection.personUID);
    const personsToMergeIds = personsToMerge?.map((person) => person.personUID);

    const getScoreWithLabels = () => {
        return authService.hasPermissions(Permissions.show_key_individuals_score)
            ? CommonService.getAllLabelsAndScoreForToolTip(person.labels, person.score, watchlists)
            : allLabels;
    };

    const getBorderColor = () => {
        if (borderColor) return borderColor;

        if (mergePersonTarget?.personUID === person.personUID) return Colors.accent;

        if (personsToMergeIds?.includes(person.personUID)) return Colors.clickable;

        if (mergePersonTarget?.personUID) return;

        if (seedIds.includes(person.personUID)) return AvatarColor.seed;

        if (selectedConnection?.personUID === person.personUID || multipleConnectionsIds.includes(person.personUID))
            return AvatarColor.selectedConnection;

        return AvatarColor.regular;
    };

    return (
        <Grid
            item
            key={person.personUID}
            onDoubleClick={() => (onDoubleClick ? onDoubleClick(person) : null)}
            style={{ position: 'relative', ...style }}>
            <Avatar
                className='avatar'
                imgProps={{}}
                style={{ borderColor: getBorderColor() }}
                alt={getScoreWithLabels()}
                title={getScoreWithLabels()}
                src={`/api/photo/${person.representingPhotoId}`}
                onClick={(e) => (onClick ? onClick(person, e.ctrlKey) : null)}></Avatar>
            {allLabels && (
                <div className='vv-avatar-tag-container flex-center'>
                    <CustomSVGIcon
                        type={svgIcons.tag}
                        size={16}
                        customStyle={{ fill: Colors.white, cursor: 'unset' }}></CustomSVGIcon>
                </div>
            )}
        </Grid>
    );
};

const mapStateToProps = (state: RootState) => ({
    watchlists: state.watchlists.data,
    selectedMultipleConnections: state.topology.selectedMultipleConnections
});

export default connect(mapStateToProps)(VvAvatar);
